import { getExceedStockList, saveAplly,exportStock } from "./api.js";
import navList from "../navList";
import Util from "@/utils/utils";
import { getDeliveryAmountFind, getTOF } from "@/views/stockCenter/adjustmentApply/allot/api";
import { sysconfig } from "@/views/order/channelExamine/api";

export default {
  data() {
    return {
      expLoading:false,
      pageLoadFlag: false,
      apllyMode: false, //申请模式
      navList: [],
      breadcrumbData: [
        {
          path: "/index",
          name: "index",
          title: "首页"
        },
        {
          path: "/stockCenter/exceedStock",
          name: "exceedStock",
          title: "可共享库存明细"
        }
      ],
      pager: {
        count: 0,
        pageNo: 1,
        pageSize: 10,
        sortName: "",
        sortOrder: "",
        sortNameApply: "",
        sortOrderApply: ""
      },
      scroll: {
        x: 2200
        // y: 400
      },
      scrollApply: {
        x: 2200
        // y: 400
      },
      columns: [
        {
          label: "销售组织",
          prop: "orgName",
          sortable: "custom",
          sortName: "orgName",
          key: "orgName"
        },
        {
          label: "产品型号",
          prop: "modelnum",
          sortable: "custom",
          sortName: "modelnum",
          width: "250px",
          key: "modelnum"
        },
        {
          label: "颜色",
          prop: "z_color",
          key: "z_color"
        },
        {
          label: "物料组",
          prop: "materialgroup",
          key: "materialgroup"
        },
        {
          label: "仓库",
          prop: "warehouse",
          key: "warehouse"
        },
        // 新增
        // {
        //   label: "是否预警",
        //   prop: "isWaring",
        //   key: "isWaring",
        //   width:120
        // },
        {
          label: "库龄",
          prop: "invage",
          align: "right",
          key: "invage"
        },
           
        {
          label: "超共享限制天数",
          prop: "overduedays",
          width: 150,
          align: "right",
          key: "overduedays"
        },
        {
          label: "入库日期",
          prop: "ininvdate",
          width: "150px",
          key: "ininvdate"
        },
        {
          label: "实际库存",
          prop: "baseqty",
          align: "right",
          key: "baseqty"
        },
        {
          label: "可用数量",
          prop: "avbbaseqty",
          align: "right",
          key: "avbbaseqty"
        },
        {
          label: "可共享数量",
          prop: "avbshareqty",
          align: "right",
          key: "avbshareqty"
        },
        {
          label: "是否已共享",
          prop: "share",
          key: "share",
          slot: "share"
        },
        {
          label: "库存状态",
          prop: "invtypeName",
          key: "invtypeName"
        },
        {
          label: "质量等级",
          prop: "qualitylv",
          key: "qualitylv"
        },
        // 新增
        {
          label: "库存类别",
          prop: "invstatusName",
          key: "invstatusName"
        },

        {
          label: "补差类型",
          prop: "hifi_priceprottype",
          key: "avbbaseqty"
        },
        {
          label: "物料号",
          prop: "material",
          key: "material",
          width: "150px"
        },
       
      
    
      ],
      columnsApply: [
        {
          label: "销售组织",
          prop: "orgName",
          sortable: "custom",
          sortName: "orgName",
          key: "orgName"
        },
        {
          label: "产品型号",
          prop: "modelnum",
          width: "250px",
          sortable: "custom",
          sortName: "modelnum",
          key: "modelnum"
        },
        {
          label: "颜色",
          prop: "z_color",
          key: "z_color"
        },
        {
          label: "物料组",
          prop: "materialgroup",
          key: "materialgroup"
        },
        {
          label: "仓库",
          prop: "warehouse",
          key: "warehouse",
          width:150
        },
        {
          label: "库存状态",
          prop: "invtypeName",
          width: "100px",
          key: "invtypeName"
        },
        {
          label: "质量等级",
          prop: "qualitylv",
          width: "100px",
          key: "qualitylv"
        },
        {
          label: "申请共享数量",
          prop: "",
          width: 200,
          slot: "count"
        },
        {
          label: "价格",
          prop: "price",
          width: 200,
        },
        {
          label: "实际库存",
          prop: "baseqty",
          align: "right",
          key: "baseqty"
        },
        {
          label: "可用数量",
          prop: "avbbaseqty",
          align: "right",
          key: "avbbaseqty"
        },
        {
          label: "可共享数量",
          prop: "avbshareqty",
          align: "right",
          key: "avbshareqty"
        },
        {
          label: "物料号",
          prop: "material",
          key: "material",
          width: "150px"
        },
        {
          label: "共享标识",
          prop: "share",
          key: "share",
          slot: "share"
        },
        {
          label: "入库日期",
          prop: "ininvdate",
          width: "150px",
          key: "ininvdate"
        },
        {
          label: "库龄",
          prop: "invage",
          align: "right",
          key: "invage"
        },
        {
          label: "超期天数",
          prop: "overduedays",
          align: "right",
          key: "overduedays"
        }
      ],
      data: [],
      onlyShowChecked: false,
      selRows: [], //选总行scroll
      selectedRowKeys: [],
      params: {},
      sjTotal:0,
      total:0,
      cherkStatus:false,
      visibleAAA:false,
      showInfo:'',
      msgStatus:'',
      openCloseBtn: false, //
      showTips: false, //
      smallLoanSwitch: false, // 小贷总开关
    };
  },
  computed: {
    //表格数据/切换只显示勾选使用
    filterData() {
      const selRow = this.selRows
      if (this.onlyShowChecked) {
        const gicIds = this.selRows.map(it => it.gicId);
        const resu = this.data.filter(it => {
          return gicIds.includes(it.gicId);
        });
        return resu;
      } else {
        return this.data;
      }
    }
  },
  created() {
    this.getLoanSwitch()
    this.getData();
    this.navList = navList.nav1;
    this.getisKQ()

  },
  methods: {
    // 获取小贷总开关
    getLoanSwitch() {
      sysconfig({key:'QD_OPEN_SMALL_LOANS'}).then(res => {
        this.smallLoanSwitch = res.data.data == 'Y' ? true : false
      })
    },
    // 判断当前账户是否签约贷款合同
    getisKQ(){
      let data = {
        serviceCode:'queryDeliveryAmountFind',
        mdmCode:this.$store.state.user.userInfo.customer.customerCode
      }
      getDeliveryAmountFind(data).then(res=>{
        if(res.data.success == 'true'){
          this.showTips = res.data.msg=='true'?true:false
          this.cherkStatus = true
          this.$forceUpdate()
        }else {
          this.openCloseBtn = false
          this.cherkStatus = false
        }
        // 如果小贷总开关和开关都开启  文字就展示
        if(this.smallLoanSwitch && this.showTips ) {
          this.openCloseBtn = true
          this.cherkStatus = true
        } else {
          this.openCloseBtn = false
          this.cherkStatus = false
        }
      })
    },
    changeStatus(){
      this.cherkStatus = !this.cherkStatus
      this.$forceUpdate()
    },
    getRowKey (row) {
      return row.gicId
    },
    clearSelection() {
      this.selectedRowKeys = [];
      this.selRows = [];
      this.$refs.tabIns.$refs.tabInst.clearSelection();
    },
    //行选中，取消
    onChange(selRows) {
      this.selRows = selRows;
      this.selectedRowKeys = selRows.map(it=>it.gicId);
    },
    showApply() {
      this.apllyMode = true;
      this.pager.pageNo = 1;
      this.getData();
    },
    hideApply() {
      this.apllyMode = false;
      this.clearSelection();
      this.getData();
    },
    searchFun(arg) {
      this.pager.pageNo = 1;
      this.params = arg[0];
      this.getData();
    },
    //排序change事件
    onTableChange( sorter) {
      const sortName = this.columns.filter(it=>it.prop==sorter.prop)
      if(sortName.length<1){
        return
      }
      if (sorter && sorter.order) {
        this.pager.sortOrder =
            sorter.order == "ascending"
                ? "asc"
                : sorter.order == "descending"
                ? "desc"
                : "";
        this.pager.sortName = sortName[0].sortName;
      } else {
        this.pager.sortOrder = "";
        this.pager.sortName = "";
      }
      this.getData();
    },
    onTableChangeApply(pagination, filters, sorter) {
      const sortName = this.columnsApply.filter(it=>it.prop==sorter.prop)
      if(sortName.length<1){
        return
      }
      if (sorter && sorter.order) {
        this.pager.sortOrderApply =
            sorter.order == "ascending"
                ? "asc"
                : sorter.order == "descending"
                ? "desc"
                : "";
        this.pager.sortNameApply = sortName[0].sortName;
      } else {
        this.pager.sortOrderApply = "";
        this.pager.sortNameApply = "";
      }
      this.getData();
    },
    exportFile(){
      this.expLoading = true;
      const data = {
        pageNo: 1,
        pageSize: 300000,
        orderByName: this.pager.sortName,
        orderByType: this.pager.sortOrder,
        orgId: "",
        matkl: "",
        shareFlag: "",
        productName: "",
        matnr: "",
        stockId: "",
        qualityGrade: "",
        dateEnd: "",
        dateStart: "",
        exceedDaysStart: "",
        exceedDaysEnd: "",
        ...this.params
      };
      if (this.apllyMode) {
        data.shareFlag = "0";
        data.orderByName = this.pager.sortNameApply;
        data.orderByType = this.pager.sortOrderApply;
      }
      exportStock(data).then(res => {
        Util.blobToJson(res.data).then(content=>{
          if(content&&content.msg=='success'){
            this.$message.success('下载成功，请到下载中心查看!')
          }
        }).catch(err=>{
          Util.downloadFile(res.data,'超期库存.xls')
        }).finally(()=>{
          this.expLoading = false;
        })
      }).catch(err=>{
        this.expLoading = false;
      });
    },
    getData() {
      const data = {
        pageNo: this.pager.pageNo,
        pageSize: this.pager.pageSize,
        orderByName: this.pager.sortName,
        orderByType: this.pager.sortOrder,
        orgId: "",
        matkl: "",
        shareFlag: "",
        productName: "",
        matnr: "",
        stockId: "",
        qualityGrade: "",
        dateEnd: "",
        dateStart: "",
        exceedDaysStart: "",
        exceedDaysEnd: "",
        ...this.params
      };
      if (this.apllyMode) {
        data.shareFlag = "0";
        data.orderByName = this.pager.sortNameApply;
        data.orderByType = this.pager.sortOrderApply;
      }
      getExceedStockList(data).then(res => {
        const start = (this.pager.pageNo - 1) * this.pager.pageSize;
        res.data.list = res.data.list || [];
        res.data.list.forEach(item => {
          item.quantity = Math.max(item.avbshareqty - (item.checkqty || 0), 0);
          item.maxQuantity = item.quantity;
        });
        this.data = res.data.list.slice(start, start + this.pager.pageSize);
        this.pager.count = res.data.list.length;
        this.sjTotal = res.data.sjTotal
        this.total = res.data.total
        this.selRows = [];
      });
    },
    gotoCK(){
      this.$router.push({
        path:"/stockCenter/myStock"
      });
    },
   async submit() {
     if(this.openCloseBtn){
       if (!this.cherkStatus) {
         this.$message.warning("请确认质物明细！");
         return;
       }
     }
      let applys = "";
      let flag = true;
      if (this.selRows.length < 1) {
        // this.$message.warning("请先勾选要共享的商品!");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000092"
        });
        this.$message.warning(msg && msg.msgDesc);
        return;
      }
      let TJmoney = 0
      // gicId+"，"+申请数量+","+来源表示+"ytjl"+gicId+"，"+申请数量+...
      for (let i = 0; i < this.selRows.length; i++) {
        if (this.selRows[i].quantity > 0) {
          flag = false;
        }
        applys += this.selRows[i].gicId;
        applys += ",";
        applys += this.selRows[i].quantity;
        applys += ",1";
        if (i != this.selRows[i].length - 1) {
          applys += "ytjl";
        }
        // 需要等gic增加价格以后走这一条 目前先隐藏
        TJmoney+= (this.selRows[i].price * this.selRows[i].quantity)
      }
      if (flag) {
        // this.$message.warning("共享数不能为0!");
        const msg = this.$configTotal({
          route: this.$route.fullPath,
          id: "14180000093"
        });
        this.$message.warning(msg && msg.msgDesc);
        return;
      }


      await saveAplly({ applys: applys }).then(res => {
        if (res.data.code == 0) {
          const appleCode = res.data.msg.replace("提交成功，申请单号为：", "");
          this.$router.push({
            path: "/stockCenter/applySucces",
            query: {
              b2bOrderCode: appleCode,
              orgName: "订单号"
            }
          });
        } else {
          this.showInfo = res.data.msg
          this.visibleAAA = true

        }
      });
    },
    gotoApplyLog() {
      this.$router.push("/stockCenter/shareLog");
    }
  }
};
